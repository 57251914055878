
import 'swiper/swiper.min.css'

export default {
  props: {
    sliderName: {
      type: String,
      require: true,
    },
    slidesPerView: {
      type: Number,
      default: 1,
    },
    slidesPerViewMobile: {
      type: Number,
      default: 1,
    },
    spaceBetween: {
      type: Number,
      default: 0,
    },
    shiftArrow: {
      type: Boolean,
      default: false,
    },
    centeredSlides: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      slider: null,
    }
  },
  mounted() {
    this.$nextTick(async () => {
      this.swiper = await this.initSwiper()
    })
  },
  methods: {
    async initSwiper() {
      const { Swiper, Navigation, Pagination, Autoplay } = await import(
        'swiper'
      ).then((module) => {
        return {
          Swiper: module.default,
          Navigation: module.Navigation,
          Pagination: module.Pagination,
          Autoplay: module.Autoplay,
        }
      })
      Swiper.use([Navigation, Pagination, Autoplay])
      return new Swiper(this.$el.querySelector(`.${this.sliderName}-swiper`), {
        slidesPerView: this.slidesPerViewMobile ? this.slidesPerViewMobile : 1,
        spaceBetween: this.spaceBetween,
        autoplay: {
          delay: 5000,
          reverseDirection: true,
        },
        loop: true,
        centeredSlides: true,
        navigation: {
          nextEl: `.${this.sliderName}-button-prev`,
          prevEl: `.${this.sliderName}-button-next`,
        },
        breakpoints: {
          781: {
            slidesPerView: this.slidesPerView,
            centeredSlides: this.centeredSlides,
          },
        },
      })
    },
  },
  beforeDestroy() {
    if (!this.swiper) {
      return
    }
    this.swiper.destroy()
  },
}
