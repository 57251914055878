import { render, staticRenderFns } from "./Pickups.vue?vue&type=template&id=873be60a&scoped=true&"
import script from "./Pickups.vue?vue&type=script&lang=js&"
export * from "./Pickups.vue?vue&type=script&lang=js&"
import style0 from "./Pickups.vue?vue&type=style&index=0&id=873be60a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.10.1_advha552eakciyizojust5gnre/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "873be60a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SliderItem: require('/home/runner/work/workation-web/workation-web/app/components/common/SliderItem.vue').default,Slider: require('/home/runner/work/workation-web/workation-web/app/components/common/Slider.vue').default})
