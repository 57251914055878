
export default {
  data() {
    return {
      pickups: [],
    }
  },
  mounted() {
    this.fetchPickups()
  },
  methods: {
    async fetchPickups() {
      const pickups = await this.$contentful
        .getEntries({
          locale: this.$i18n.locale,
          content_type: 'magazine',
          'fields.pickup': true,
          order: '-fields.publishDate',
          limit: 8,
        })
        .then((res) => {
          return res.items
        })
        .catch((err) => {
          this.$sentry.captureException(err)
          return []
        })
      this.pickups = pickups
    },
  },
}
